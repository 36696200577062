import React, { Component } from "react";
import "../assets/css/VirtualTerminalComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar"
import Footer from "./Footer";
import BlueSection from "./BlueSection"

class VirtualTerminalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: null,

    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
  }

  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding virtual-terminal">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={"fixed-bg"}
          ></div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 info text-light donation-form">
                  <StripeContainer updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  amount={this.state.amount}  
                  updateAmount={this.updateAmount}
                  formType="VirtualTerminal"
                  />
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default VirtualTerminalComponent;
