import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar"
import Footer from "./Footer";
import ThankYouPage from "./ThankYouPage"
import BlueSection from "./BlueSection"

class DonationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: 100,
      payFees: false,
      firstName: ""
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
  }

  updateFirstName = (firstName) => {
    this.setState({firstName: firstName})
  }

  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
          ></div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? <ThankYouPage amount={this.state.amount} 
                updateHasDonated={this.updateHasDonated} firstName={this.state.firstName}  /> : (
                  <StripeContainer updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  amount={this.state.amount}  
                  updateAmount={this.updateAmount}
                  payFees={this.state.payFees}
                  togglePayFees={this.togglePayFees}
                  updateFirstName={this.updateFirstName}
                  />
                )}
                
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default DonationComponent;
