import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DonationOptions from "./DonationOptions";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  stateOptions,
  provinceOptions,
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import NameForm from "./NameForm";
import BillingForm from "./BillingForm";
import NotifyContactForm from "./NotifyContactForm";
import TributeForm from "./TributeForm";
import AmountForm from "./AmountForm";
import { useLocation } from "react-router-dom";
import SingleChoiceDropdown from "./SingleChoiceDropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const DonationForm = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const extractFirstAndLastName = (fullName) => {
    if (!fullName || typeof fullName !== "string" || fullName.trim() === "") {
      return { firstName: "", lastName: "" };
    }

    const names = fullName.trim().split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : "";

    return { firstName, lastName };
  };

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 1000,
      },
    });

    const applePaySupported = window.ApplePaySession && window.ApplePaySession.canMakePayments();
    setApplePay(applePaySupported);

    if (applePaySupported) {
      setPaymentRequest(pr);
    }


    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        //setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      const { firstName, lastName } = extractFirstAndLastName(e.payerName);
      updateFirstName(firstName);
      const originURL = window.location.href;

      if(!formData.monthly) {

        const { error: backendError, clientSecret } = await fetch(
          `${process.env.REACT_APP_API_URL}/create-payment-intent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentMethodType: "card",
              currency: "cad",
              amount: calculateTotalAmount(amount, payFees) * 100,
              originalAmount: amount * 100,
              payFees: payFees,
              email: e.payerEmail,
              firstName: firstName,
              lastName: lastName,
              phoneNumber: e.payerPhone,
              address: e.shippingAddress,
              originURL: originURL,
              monthly: formData.monthly
            }),
          }
        ).then((r) => r.json());
  
        if (backendError) {
          toast.error(backendError.message);
          return;
        }
  
        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            { handleActions: false }
          );
  
        if (stripeError) {
          // Show error to your customer (e.g., insufficient funds)
          toast.error(stripeError.message);
  
          // Complete the payment request to dismiss the Apple Pay dialog on error
          e.complete("fail");
          return;
        }
  
        toast.success(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      } else {
        const backendResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/subscriptions/alternativePay`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              applePayToken: e.paymentMethod.id,
              currency: "cad",
              amount: calculateTotalAmount(amount, payFees) * 100,
              originalAmount: amount * 100,
              payFees: payFees,
              email: e.payerEmail,
              firstName: firstName,
              lastName: lastName,
              phoneNumber: e.payerPhone,
              address: e.shippingAddress,
              originURL: originURL,
              monthly: formData.monthly
            }),
          }
        ).then((r) => r.json());

      if (backendResponse.error) {
        toast.error(backendResponse.error);
        return;
      }

      toast.success("You have been subscribed! Thank you for your monthly donation!");
      }


      // Complete the payment request to dismiss the Apple Pay dialog
      e.complete("success");

      updateHasDonated(true);
    });
  }, [stripe, elements, amount, payFees, updateFirstName, formData]);

  const roundCurrency = (amount) => {
    return Math.ceil(amount * 100) / 100;
  };

  const calculateStripeFees = (amount) => {
    let baseStripeFee = 2.9;
    const additionalInternationalFee = 0.8;

    if (formData.selectedCountry.value !== "CAN") {
      baseStripeFee += additionalInternationalFee;
    }

    const stripeFees = roundCurrency(amount * (baseStripeFee * 0.01) + 0.3);

    return stripeFees;
  };

  const calculateOurFees = (amount) => {
    return roundCurrency(amount * 0.7 * 0.01);
  };

  const calculateInitialAmountGuess = (amount) => {
    const totalAmount =
      amount + calculateStripeFees(amount) + calculateOurFees(amount);

    return totalAmount;
  };

  const calculateTotalAmount = (initialAmount, canPayFees) => {
    const amount = Number(initialAmount);

    if (isNaN(amount)) {
      return 0;
    }

    if (!canPayFees) {
      return amount;
    }

    const initialGuess = calculateInitialAmountGuess(amount);
    const stripeFees = calculateStripeFees(initialGuess);
    const ourFees = calculateOurFees(initialGuess);

    const remainingAmount = initialGuess - stripeFees - ourFees;

    if (remainingAmount < initialAmount) {
      return roundCurrency(initialGuess + (initialAmount - remainingAmount));
    }

    return roundCurrency(initialGuess);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      By covering the transaction fee, Big Sisters of BC Lower Mainland will
      keep 100% of your donation.
    </Tooltip>
  );

  const updatePaymentRequestInstance = async (event) => {
    if (!amount) {
      updateAmount(1000);
    }

    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Donation to Big Sisters",
        amount: calculateTotalAmount(amount, payFees) * 100,
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleChoiceUpdate = (option, label) => {
    console.log(option.value);
    console.log(label);

    setFormData({
      ...formData,
      [label]: option.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      toast.error(
        "Payment failed. Please try again or use a different credit card."
      );
      updateIsLoading(false);
      return;
    }

    const originURL = window.location.href;

    try {
      let body = { ...formData };
      body["paymentMethodId"] = paymentMethod.id;
      body["province"] = body.selectedProvince.value;
      body["country"] = body.selectedCountry.value;
      body["selectedWording"] = body.selectedWording.value;
      body["provinceOptions"] = "";
      body["amount"] = calculateTotalAmount(amount, payFees);
      body["billingProvince"] = body.billingSelectedProvince.value;
      body["billingCountry"] = body.billingSelectedCountry.value;
      body["originalAmount"] = amount;
      body["payFees"] = payFees;
      body["originURL"] = originURL;

      updateFirstName(body["firstName"]);

      const endpoint = formData.monthly ? "subscriptions" : "payments";

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        toast.error("Payment Error! Please try again later.");
      } else {
        const data = await response.json();
        console.log(data); // Success message from backend

        if (formData.monthly) {
          toast.success("You have been subscribed! Thank you for your monthly donation!");
        } else {
          toast.success("Payment successful! Thank you for your donation!");
        }
        

        setFormData(initialState);

        updateHasDonated(true);
      }
      updateIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Payment failed. Please try again.");
      updateIsLoading(false);
    }
  };

  const handleCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleBillingCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      billingSelectedCountry: selectedOption,
    };

    if (newLabel !== formData.billingProvinceLabel) {
      updatedState.billingProvinceLabel = newLabel;
      updatedState.billingProvinceOptions = options;
      updatedState.billingSelectedProvince = options[0];
      updatedState.billingZipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleAmountClick = (amount) => {
    updateAmount(amount);

    const amountInput = document.getElementById("amount");
    if (amountInput) {
      amountInput.value = amount.toString();
    }
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();

    try {
      paymentRequest.show();
    } catch (updateError) {
      toast.error(`Apple Pay is not setup on this device!`);

      const applePaySetupURL = "https://support.apple.com/en-ca/guide/iphone/iph9b7f53382/ios";
      window.open(applePaySetupURL, "_blank");
    }
  };

  const formatAsCurrency = (amount) => {
    // Assuming amount is a number
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: "#2dccd3" }}>
            {isMonthly ? "Make it Monthly!" : "Donate Today"}
          </h3>
          <p style={{ color: "#232323" }}>
            Make a donation to Big Sisters of BC Lower Mainland and help create
            life-changing opportunities for youth in our community. Give them
            the support they need now and in the future.
          </p>

          <DonationOptions
            handleAmountClick={handleAmountClick}
            amount={amount}
            handleCustomAmount={toggleValue}
          />
          <br />
          <div className="row  text-light">
            <AmountForm
              handleInputChange={handleInputChange}
              formData={formData}
              toggleValue={toggleValue}
              isMonthly={isMonthly}
              amount={amount}
            />

            <div className="col-md-12">
              <div className="form-group">
                <div className="d-flex flex-wrap align-items-center">
                  <label
                    style={{ display: "flex", alignItems: "center" }}
                    className="coverFeeOption"
                  >
                    <input
                      type="checkbox"
                      onChange={togglePayFees}
                      checked={payFees}
                      style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                    />
                    &nbsp;&nbsp;I will generously cover the&nbsp;
                    <span style={{ fontWeight: "bold" }}>transaction fees</span>
                    .&nbsp;
                    <OverlayTrigger placement="right" overlay={tooltip}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ color: "#2dccd3" }}
                      />
                    </OverlayTrigger>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                {payFees && amount && amount > 0 && (
                  <>
                    <p style={{ color: "black" }}>
                      Your donation will come to{" "}
                      {formatAsCurrency(calculateTotalAmount(amount, payFees))}.
                    </p>
                  </>
                )}
              </div>
            </div>

            {applePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="ApplePay">Donate by Apple Pay</label>
                  <div className="input-group">
                    <ApplePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            {googlePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="GooglePay">Donate by Google Pay</label>
                  <div className="input-group">
                    <GooglePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            <NameForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
              creditCardSectionTitle={
                applePay || googlePay
                  ? "Or Credit Card Payment"
                  : "Credit Card Payment"
              }
            />

            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Donate by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>

            <AddressForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <BillingForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            {!formData.monthly && <>
            
              <SingleChoiceDropdown
              value={formData.anonymous}
              toggleValue={(e) => toggleValue(e, "anonymous")}
              label="Make it anonymous?"
              identifier="anonymous"
              outerClass="col-md-12"
            />
            <SingleChoiceDropdown
              value={formData.tribute}
              toggleValue={(e) => toggleValue(e, "tribute")}
              label="Is it a tribute?"
              identifier="tribute"
              outerClass="col-md-12"
            />

            {formData.tribute && (
              <TributeForm
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                toggleValue={toggleValue}
              />
            )}

            {formData.notify && (
              <NotifyContactForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleProvinceChange={handleProvinceChange}
                toggleValue={toggleValue}
                handleCountryChange={handleCountryChange}
                handleChoiceUpdate={handleChoiceUpdate}
              />
            )}
            </>}


            <button className="btn btn-theme effect btn-md" type="submit">
              Donate Now
            </button>

            <div className="question">
              <div className="icon">
                <i className="fas fa-phone"></i>
              </div>

              <div className="info">
                <h5 style={{ color: "rgb(45, 204, 211)" }}>
                  Have any questions about your donation?
                </h5>
                <span style={{ color: "#232323" }}>
                  Call Now: 1-604-873-4525
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DonationForm;